<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.health.header') }}</b>
    </h3>
    <hr />
    <div v-html="$t('FAQ.health.message1')"></div>
    <br />
    <div v-html="$t('FAQ.health.message2')"></div>
    <br />
    <div v-html="$t('FAQ.health.message3')"></div>
    <br />
    <div v-html="$t('FAQ.health.message4')"></div>
    <br />
    <div v-html="$t('FAQ.health.message5')"></div>
    <ul>
      <li v-html="$t('FAQ.health.list1')"></li>
      <li v-html="$t('FAQ.health.list2')"></li>
    </ul>
    <br />
    <div v-html="$t('FAQ.health.message6')"></div>
    <div class="wrapper--img">
      <img
        src="https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/static/Covid+Status/covid+status+1.png"
        alt=""
      />
    </div>
    <ul>
      <li v-html="$t('FAQ.health.step1')"></li>
    </ul>
    <div v-html="$t('FAQ.health.statustitle')"></div>
    <ul>
      <li v-html="$t('FAQ.health.status1')"></li>
      <li v-html="$t('FAQ.health.status2')"></li>
      <li v-html="$t('FAQ.health.status3')"></li>
    </ul>
    <div class="wrapper--img">
      <img
        style="height: 250px; object-fit: contain; width: auto"
        src="https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/static/Covid+Status/covid+status+2.png"
        alt=""
      />
    </div>
    <ul>
      <li v-html="$t('FAQ.health.step2')"></li>
    </ul>
    <div class="wrapper--img">
      <img
        src="https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/static/Covid+Status/covid+status+3.png"
        alt=""
      />
    </div>
    <ul>
      <li v-html="$t('FAQ.health.step3')"></li>
    </ul>
    <div class="wrapper--img">
      <img
        src="https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/static/Covid+Status/covid+status+4.png"
        alt=""
      />
    </div>
    <ul>
      <li v-html="$t('FAQ.health.step4')"></li>
    </ul>
    <br />
    <br />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
